<template>
  <div class="company-confirm">
    <el-alert title="申请企业认证,获取更多权益" force-aligned type="warning" />
    <div class="confirm">
      <el-button type="primary" size="small" @click="confirm">
        申请企业认证
      </el-button>
    </div>
    <div class="result">
      <div v-if="status === '认证'" class="success">
        <el-alert type="success" closable="false">
          认证成功
        </el-alert>
      </div>
      <div v-if="status === '未认证'" class="fail">
        <el-alert type="error" :closable="false">
          认证失败前往<a class="fail-text" href="https://www.shopex.cn/usercenter/user/approve" target="_blank">用户中心</a>认证
        </el-alert>
      </div>
    </div>
  </div>
</template>
<script>
import { isCorpAuthentication } from '@/api/setting';
export default {
  data () {
    return {
      status: null,
    };
  },
  methods: {
    async confirm () {
      const res = await isCorpAuthentication();
      this.status = res;
    },
  },
};
</script>
<style scoped lang='scss'>
.company-confirm {
  height: 100%;
  background: #fff;
  padding: 24px;
  min-height: 500px;
  .confirm {
    width: 300px;
    display: flex;
    margin: 24px 0;
  }
  .result {
    .success {
    }
    .fail {
      &-text {
        text-decoration: none;
      }
    }
  }
}
</style>